import React, { useRef, useEffect, useState } from "react"
import styled from "styled-components"
import { Col } from "styled-bootstrap-grid"
import breakpoints from "../../styles/breakpoints"
import Img from "gatsby-image"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import Modal from "styled-react-modal"
import close from "../../resources/img/ui/close.svg"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Slider from "react-slick/lib"
import arrowLeft from "../../resources/img/ui/arrow-fixed-resize.png"
import arrowRight from "../../resources/img/ui/arrow-right-fixed-resize.png"

const CloseButton = styled.div`
  background-color: transparent;
  border: none;
  outline: none;
  width: 24px !important;
  height: 24px !important;
  position: fixed;
  display: grid;
  justify-content: center;
  align-items: center;
  top: 20px;
  right: 20px;
  z-index: 2;
  cursor: pointer;
  img {
    width: 20px !important;
    height: 20px !important;
    object-fit: contain;
  }
  :hover {
    background-color: ${colors.neonYellow};
  }
  @media (min-width: ${breakpoints.md}) {
    width: 30px !important;
    height: 30px !important;
  }
`

const ArrowBtnMobile = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  width: 20px;
  height: 25px;
  background-image: ${(props) =>
    props.left ? `url(${arrowLeft})` : `url(${arrowRight})`};
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  background-size: contain;
  z-index: 10;
  left: ${(props) => props.left && "0px"};
  right: ${(props) => props.right && "0px"};
  top: calc(50% + 2px);
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease-out;
  &.visible {
    opacity: 1;
  }
  opacity: 1;
  @media (min-width: ${breakpoints.sm}) {
    left: ${(props) => props.left && "50px"};
    right: ${(props) => props.right && "50px"};
  }
  @media (min-width: ${breakpoints.lg}) {
    left: ${(props) => props.left && "0"};
    right: ${(props) => props.right && "0"};
    display: none;
  }
`

const ImageStyled = styled(Img)`
  max-height: 500px;
  height: auto;
  img {
    width: 100%;
    object-fit: contain !important;
  }
  @media (min-width: ${breakpoints.xs}) {
    max-height: none;
  }
  @media(min-width: ${breakpoints.sm}){
    height: 100%;
    img {
      height: 100% !important;
    }
  }
`

const ImageContainer = styled.div`
  padding: ${props => props.horizontal ? ' 38px 25px' : '38px'};
  margin: auto;
  width: 420px;
  position: relative;
  video {
    width: 100%;
    height: 100%;
  }
  @media (min-width: ${breakpoints.sm}) {
    padding: 30px;
    height: 600px;
  }
  @media (min-width: ${breakpoints.md}) {
    width: 100%;
    max-width: 450px;
  }
  @media (min-width: ${breakpoints.lg}) {
    z-index: -1;
    max-width: 700px;
    height: 100%;
  }
  @media (min-width: ${breakpoints.xl}) {
    max-width: 920px;
  }
  @media (min-width: ${breakpoints.xxl}) {
    max-width: 1000px;
  }  
`

const ContentContainer = styled.div`
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 95vh;
  position: relative;
  outline: none;
  @media (min-width: ${breakpoints.sm}) {
    min-height: 100vh;
    height: 100%;
    padding: 0;
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: 100vh;
    height: 100vh;
    margin-top: 0px;
  }
`

const MobileText = styled.div`
  ${fonts.nhassGroteskRoman};
  font-size: 8.7px;
  line-height: 14.4px;
  letter-spacing: 0.02em;
  max-width: 157px;
  margin-top: 30px;
  right: 36px;
  position: absolute;
  @media (min-width: ${breakpoints.sm}) {
    margin-top: 30px;
    right: -100px;
  }
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`

const DesktopText = styled.div`
  display: none;
  ${fonts.nhassGroteskRoman};
  font-size: 8.7px;
  line-height: 14.4px;
  letter-spacing: 0.02em;
  max-width: 157px;
  margin-top: 30px;
  position: absolute;
  @media (min-width: ${breakpoints.md}) {
    display: block;
    bottom: 30px;
    right: 24px;
  }
  @media (min-width: ${breakpoints.xl}) {
    right: 34px;
  }

`

const StyledSlider = styled(Slider)`
  .slick-track {
    display: flex;
    align-items: center;
    height: 100vh;
  }
  @media (min-width: ${breakpoints.sm}) {
    .slick-track {
      height: auto;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    .slick-list {
      display: block;
    }
  }
`

const ColStyled = styled(Col)`
  display: none;
  @media (min-width: ${breakpoints.lg}) {
    display: block;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    ${({ left }) => (left ? "left: 0" : "right: 0")};
    cursor: none;
  }
`

const Cursor = styled.div`
  display: none;
  @media (min-width: ${breakpoints.lg}) {
    display: block;
    width: 14px;
    height: 30px;
    background-image: ${({ isLeft }) =>
      isLeft ? `url(${arrowLeft})` : `url(${arrowRight})`};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    position: fixed;
    cursor: none;
    opacity: 0;
    transition: opacity 0.2s ease-out;
    z-index: 1;
    pointer-events: none;
    &.visible {
      opacity: 1;
    }
  }
`

const Video = styled.video`
  width: 100%;
  height: 100%;
`

const SliderContainer = styled.div`
  position: relative;
  height: 95vh;
  margin-top: -20px;
  @media (min-width: ${breakpoints.sm}) {
    height: auto;
    margin-top: 0;
  }
`

const RotateText = styled.div`
  display: block;
  padding-top: 14px;
  font-size: 8.8px;
  line-height: 12.8px;
  ${fonts.nhassGroteskRoman};
  color: ${colors.black};
  opacity: 0.44;
  letter-spacing: 0.264px;
  position: absolute;
  
  @media(min-width: ${breakpoints.md}){
    display: none;
  }
`;

const WorkModal = ({
  works,
  isOpen,
  setIsOpen,
  currentImage,
  setCurrentImage,
}) => {
  const slide = useRef(null)
  const cursorRef = useRef(null)
  const [isCursorActive, setIsCursorActive] = useState(false)
  const [isTouched, setIsTouched] = useState(true)
  const [isLeft, setIsLeft] = useState(false)
  let secondsSincesLastActivity = 0
  const maxInactivity = 2

  const activityWatcher = () => {
    setInterval(() => {
      secondsSincesLastActivity++
      if (secondsSincesLastActivity > maxInactivity) {
        setIsCursorActive(false)
        setIsTouched(false)
      }
    }, 1000)

    const activity = () => {
      setIsCursorActive(true)
      setIsTouched(true)
      secondsSincesLastActivity = 0
    }
    document.addEventListener("mousemove", activity)
    document.addEventListener("touchstart", activity)
  }

  const cursor = (e) => {
    if (cursorRef.current !== null) {
      cursorRef.current.style.top = e.clientY + "px"
      cursorRef.current.style.left = e.clientX + "px"
    }
  }

  useEffect(() => {
    activityWatcher()
  }, [])

  useEffect(() => {
    if (typeof window !== undefined) {
      window.addEventListener("mousemove", cursor)
    }
    return () => {
      window.removeEventListener("mousemove", cursor)
    }
  })

  const next = () => {
    const slider = slide.current
    slider.slickNext()
  }

  const prev = () => {
    const slider = slide.current
    slider.slickPrev()
  }

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    swipe: true,
    swipeToSlide: true,
    draggable: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
    initialSlide: currentImage,
    adaptiveHeight: false,
    centerMode: false,
    afterChange: (current, next) => setCurrentImage(next),
  }

  return (
    <Modal isOpen={isOpen} onEscapeKeydown={() => setIsOpen(!isOpen)}>
      <Cursor
        ref={cursorRef}
        isLeft={isLeft}
        className={isCursorActive ? "visible" : null}
      />
      <CloseButton onClick={() => setIsOpen(!isOpen)}>
        <img src={close} alt={"close button"} />
      </CloseButton>
      <SliderContainer>
        <StyledSlider {...settings} ref={slide}>
          {works.map(({ image, content }, index) => {
            const isVideo = !image.fluid
            return (
              <ContentContainer
                key={index}
                onTouchStart={() => setIsTouched(true)}
                onClick={() => setIsTouched(true)}
              >
                <ImageContainer horizontal>
                  {isVideo ? (
                    <Video
                      src={image.file.url}
                      autoPlay
                      muted
                      loop
                      playsInline
                      preload="metadata"
                      aria-label="video"
                      key={index}
                    />
                  ) : (
                    <ImageStyled fluid={image.fluid} />
                  )}
                  {content && (
                    <MobileText>
                      {documentToReactComponents(content.json)}
                    </MobileText>
                  )}
                  {/* {<RotateText>*Rotate horizontal</RotateText>} */}
                </ImageContainer>
                {content && (
                  <DesktopText>
                    {documentToReactComponents(content.json)}
                  </DesktopText>
                )}
              </ContentContainer>
            )
          })}
        </StyledSlider>
        <ArrowBtnMobile
          onClick={() => prev()}
          isTouched={isTouched}
          className={isTouched ? "visible" : null}
          left
        />
        <ArrowBtnMobile
          onClick={() => next()}
          isTouched={isTouched}
          className={isTouched ? "visible" : null}
          right
        />
      </SliderContainer>
      <ColStyled
        lg={6}
        onClick={() => prev()}
        onMouseOver={() => setIsLeft(true)}
        onFocus={() => setIsLeft(true)}
        left
      />
      <ColStyled
        lg={6}
        onClick={() => next()}
        onMouseOver={() => setIsLeft(false)}
        onFocus={() => setIsLeft(false)}
        right
      />
    </Modal>
  )
}

export default WorkModal
