import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import Img from "gatsby-image"
import Video from "./Video"

const ImageContainer = styled.div`
  margin-right: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  display: none;
  @media (min-width: ${breakpoints.sm}) {
    display: inline-block;
  }
`

const DesktopContainer = styled.div`
  display: none;
  @media (min-width: ${breakpoints.sm}) {
    display: flex;
    flex-wrap: wrap;
  }
`

const Desktop = ({ works, handleClick }) => {
  return (
    <DesktopContainer>
      {works.map(({ image }, index) =>
        image.fixed ? (
          <ImageContainer
            onClick={() => handleClick(index)}
            key={index}
            className="work"
          >
            <Img fixed={image.fixed} alt={image.title} />
          </ImageContainer>
        ) : (
          <Video
            video={image.file.url}
            index={index}
            handleClick={handleClick}
            className={"work"}
            key={index}
          />
        )
      )}
    </DesktopContainer>
  )
}

export default Desktop
