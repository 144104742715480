import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"

const VideoContainer = styled.div`
  width: auto;
  overflow: hidden;
  cursor: pointer;
  video {
    width: 100%;
    height: 100%;
  }
  @media (min-width: ${breakpoints.sm}) {
    display: block;
    margin-right: 16px;
    margin-bottom: 16px;
    video {
      width: auto;
      height: 240px;
    }
  }
`
const Video = ({ video, handleClick, index, className }) => {
  const videoRef = useRef()

  useEffect(() => {
    const video = videoRef.current

    video.defaultMuted = true
    video.setAttribute("muted", "1")
    video.setAttribute("playsinline", "1")
    video.setAttribute("autoplay", "1")
  })
  return (
    <VideoContainer ref={videoRef} onClick={() => handleClick(index)} className={className || null}>
      <video
        src={video}
        autoPlay
        muted
        loop
        playsInline
        preload="metadata"
        aria-label="video"
      />
    </VideoContainer>
  )
}

export default Video
