import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import Img from "gatsby-image"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Row, Col } from "styled-bootstrap-grid"
import Video from "./Video"

gsap.registerPlugin(ScrollTrigger)

const StyledRow = styled(Row)`
  @media (min-width: ${breakpoints.sm}) {
    display: none;
  }
`

const MobileImageContainer = styled.div`
  cursor: pointer;
  padding-bottom: 8px;
  @media (min-width: ${breakpoints.sm}) {
    display: none;
  }
`

const ImgStyled = styled(Img)`
  img {
    width: 100% !important;
    height: 100% !important;
  }
`

const ColStyled = styled(Col)`
  ${({left}) => left ? "padding-right: 4px" : "padding-left: 4px"};
  div {
    outline: none;
  }
`

const Mobile = ({ works, handleClick }) => {
  const left = []
  const right = []

  works.forEach((post, index) =>
    index % 2 === 0 ? left.push({ post, index }) : right.push({ post, index })
  )

  return (
    <StyledRow>
      <ColStyled xs={6} left>
        {left.map(({ post, index }) => (
          <div onClick={() => handleClick(index)} key={index} onKeyDown={() => handleClick(index)} role="button" tabIndex={0}>
            {post.image.fluid ? (
              <MobileImageContainer>
                {post.image.fluid && (
                  <ImgStyled fluid={post.image.fluid} alt={post.image.title} />
                )}
              </MobileImageContainer>
            ) : (
              <Video video={post.image.file.url} />
            )}
          </div>
        ))}
      </ColStyled>
      <ColStyled xs={6}>
        {right.map(({ post, index }) => (
          <div onClick={() => handleClick(index)} key={index} onKeyDown={() => handleClick(index)} role="button" tabIndex={0}>
            {post.image.fluid ? (
              <MobileImageContainer>
                {post.image.fluid && (
                  <ImgStyled fluid={post.image.fluid} alt={post.image.title} />
                )}
              </MobileImageContainer>
            ) : (
              <Video video={post.image.file.url} />
            )}
          </div>
        ))}
      </ColStyled>
    </StyledRow>
  )
}

export default Mobile
