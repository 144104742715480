import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Core/Seo"
import { graphql } from "gatsby"
import PageContent from "../components/Work/PageContent"
import styled from "styled-components"
import breakpoints from "../styles/breakpoints"

const WorkContainer = styled.div`
  margin-top: 80px;
  padding: 0 18px;
  @media (min-width: ${breakpoints.md}) {
    margin-top: 120px;
    padding: 0 12px;
  }
`

const WorkTemplate = (props) => {
  const path = props.location.pathname
  const page = props.data.page
  const works = page.works

  return (
    <Layout>
      <SEO
        title={page.metaTitle}
        description={page.metaDescription}
        fullTitle={false}
        path={path}
      />
      <WorkContainer>
        <PageContent works={works} />
      </WorkContainer>
    </Layout>
  )
}

export default WorkTemplate

export const pageTemplateQuery = graphql`
  query($slug: String!) {
    page: contentfulWork(slug: { eq: $slug }) {
      id
      slug
      metaTitle
      metaDescription
      works {
        id
        content {
          json
        }
        image {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          fixed(height: 240, quality: 100) {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
          file {
            url
          }
        }
      }
    }
  }
`
