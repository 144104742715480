import React, { useState, useEffect } from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import WorkModal from "./WorkModal"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Mobile from "./Mobile"
import Desktop from "./Desktop"

gsap.registerPlugin(ScrollTrigger)

const ContainerStyled = styled.div`
  padding-bottom: 60px;
  @media (min-width: ${breakpoints.sm}) {
    padding-top: 30px;
    padding-bottom: 100px;
  }
`

const PageContent = ({ works }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState(null)

  useEffect(() => {
    gsap.set(".work", { opacity: 0 })
    ScrollTrigger.batch(".work", {
      end: "top top",
      onEnter: (batch) =>
        gsap.to(batch, { opacity: 1, stagger: 0.1, overwrite: true }),
      onLeave: (batch) => gsap.set(batch, { opacity: 1, overwrite: true }),
      onEnterBack: (batch) =>
        gsap.to(batch, { opacity: 1, stagger: 0.1, overwrite: true }),
      onLeaveBack: (batch) => gsap.set(batch, { opacity: 0, overwrite: true }),
    })
    ScrollTrigger.refresh()
  })

  const handleClick = (index) => {
    setCurrentImage(index)
    setIsOpen(!isOpen)
  }

  if (!works) return null

  return (
    <ContainerStyled>
      <Mobile works={works} handleClick={handleClick} />
      <Desktop works={works} handleClick={handleClick} />
      <WorkModal
        isOpen={isOpen}
        currentImage={currentImage}
        setCurrentImage={setCurrentImage}
        setIsOpen={setIsOpen}
        works={works}
      />
    </ContainerStyled>
  )
}

export default PageContent
